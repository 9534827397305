import React from 'react';
import TextBlock from "blocks/text/TextBlock";
import CtaBlock from "blocks/cta/CtaBlock";
import Quote from "blocks/quote/Quote";
import RoiList from "blocks/roi/RoiList";
import UspList from "blocks/usp/UspList";
import CaseBlock from "blocks/cases/CaseBlock";
import CaseCarrousel from "blocks/cases/CaseCarrousel";
import { ImageSliderRecord } from "queries/Blocks";
import ImageSlider from "blocks/images/ImageSlider";
import CompaniesList from "blocks/companies/CompaniesList";
import SimpleTextBlock from "blocks/SimpleTextBlock";
import PlanOnboarding from "blocks/planOnboarding/PlanOnboarding";
import Faq from "blocks/faq/Faq";
import CaseHeroBlock from "blocks/hero/CaseHeroBlock";
import CaseQuote from "blocks/quote/CaseQuote";
import ImageGallery from "blocks/images/ImageGallery";

function BlockMapper(props: any) {
    const blockType = props.__typename;

    if(blockType == "CaseHeroRecord") {
        return <CaseHeroBlock {...props} />;
    }

    if(blockType == "TextRecord" ) {
        return <TextBlock {...props} />;
    }

    if(blockType == "UspListRecord") {
        return <UspList {...props} />;
    }

    if(blockType == "RoiListRecord") {
        return <RoiList {...props} />;
    }

    if(blockType == "Cta2Record") {
        return <CtaBlock {...props.cta} />;
    }

    if(blockType == "QuoteRecord") {
        return <Quote {...props} />;
    }
    
    if(blockType == "CaseQuoteRecord") {
        return <CaseQuote {...props} />;
    }

    if(blockType == "CaseBlockRecord") {
        return <CaseBlock {...props} />;
    }

    if(blockType == "CaseCarrouselRecord") {
        return <CaseCarrousel {...props} />;
    }

    if(blockType == "ImageSliderRecord") {
        return <ImageSlider {...props} />;
    }

    if(blockType == "CompaniesListRecord") {
        return <CompaniesList theme={"default"} {...props} />;
    }

    if(blockType == "SimpleTextRecord") {
        return <SimpleTextBlock {...props} />;
    }

    if(blockType == "PlanOnboardingRecord") {
        return <PlanOnboarding {...props} />;
    }

    if(blockType == "FaqRecord") {
        return <Faq {...props} />;
    }

    if(blockType == "ImageGalleryRecord") {
        return <ImageGallery {...props} />;
    }

    return null;
}

export const withSection = (Component: any) => (props: any) => {
    return (
        <section>
            <Component {...props} />
        </section>
    );
};

export default withSection(BlockMapper);