import React from 'react';
import { ICase } from "blocks/cases/CaseBlock";
import Paragraph from "components/elements/Paragraph";
import Container from "components/core/Container";
import { IThemes } from "types/base";
import Link from "components/base/Link";
import Button from "components/elements/Button";
import cn from "classnames";

export type ICaseCarrousel = {
    title: string,
    description: any,
    theme: IThemes;
    cases: ICase[],
};

export default function CaseCarrousel(props: ICaseCarrousel) {
    const quoteContainerCn = cn(
        "bottom-10 absolute z-10",
    );

    const buttonCn = cn(
        "text-sm rounded mt-3",
        "lg:text-lg lg:mt-10"
    );

    return (
        <Container theme={props.theme}>
            <div className={"px-auto py-8"}>
                <div className={'w-3/4 lg:w-2/4 xl:w-1/4 lg:max-h-40 lg:mb-0'}>
                    <h2 className={"text-purple-dark font-medium text-5xl mb-5"}>
                        {props.title}
                    </h2>
                    {props.description && (
                        <Paragraph
                            className={'opacity-50 text-lg sm:text-xl mb-5'}
                            fontWeight={"light"}
                            theme={props.theme}
                        >
                            {props.description}
                        </Paragraph>
                    )}
                </div>
                <div
                    className={'flex flex-row relative overflow-x-auto space-x-2 snap snap-x snap-mandatory'}
                    style={{ height: "36rem" }}
                >
                    {props.cases.map((caseBlock, index) => {
                        return (
                            <div key={caseBlock.id}>
                                <div
                                    className={"absolute p-10 snap-start"}
                                    style={{
                                        height: "36rem",
                                        width: "22rem",
                                        left: `${23 * index}rem`,
                                        backgroundImage: `url('${caseBlock.image?.responsiveImage?.src}')`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center center",
                                    }}
                                >
                                    <div className={quoteContainerCn}>
                                        {caseBlock?.button.map((b) => {
                                            return (
                                                <Link {...b.link[0]} key={b.id}>
                                                    <Button
                                                        id={b.id}
                                                        variant={b.variant}
                                                        size={b.size}
                                                        color={b.color}
                                                        textColor={b.textColor}
                                                        icon={b.icon}
                                                        className={buttonCn}
                                                    >
                                                        {b.label}
                                                    </Button>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}