import React from 'react';
import Paragraph from "components/elements/Paragraph";
import cn from "classnames";
import { adjustAlignment } from "classes";
import { ITextAlignment, ITextSize, IThemes } from "types/base";

type Props = {
    size: ITextSize
    alignment: ITextAlignment
    theme: IThemes
    className?: string,
    container?: boolean
};

export type IRoi = {
    id: string,
    value: string,
    description: any,
    symbol: string,
};

export default function Roi(props: Props & IRoi) {
    const { value, description, size, theme, symbol, container } = props;
    const alignmentCn = cn(
        adjustAlignment(props.alignment)
    );

    const roiContainerCn = cn(
        `${props.alignment == 'center' ? 'mx-auto' : ''}`,
         "flex flex-col",
         !container && "mb-8 last:mb-16 md:mb-12 lg:mb-0 md:last:mb-0 lg:last:mb-24",
         "md:last:col-span-2 lg:last:col-span-1"
    );

    const headingCn = cn(
        alignmentCn,
        { "md:text-6xl text-5xl font-medium " : size == "large" },
        { "text-gray-900" : props.theme == "default" },
        { "text-primary-600" : props.theme == "gray" },
        { "text-white" : props.theme == "dark" },
        'mb-3'
    );

    const paragraphSize = () => {
        if(size == "small") {
            return "extra-small";
        }

        if(size == "medium") {
            return "small";
        }

        if(size == "large") {
            return "medium";
        }
    };

    const paragraphCn = cn(
        alignmentCn,
        "mx-auto",
        { "text-gray-500" : props.theme == "default" },
        { "text-gray-900" : props.theme == "gray" },
        { "text-gray-200" : props.theme == "dark" },
        'opacity-75 w-full font-light text-lg'
    );

    return (
        <div className={roiContainerCn}>
            <Paragraph className={headingCn} theme={theme} fontWeight={"light"}>
                {value}{symbol}
            </Paragraph>
            <Paragraph className={`${paragraphCn}`} theme={theme} size={paragraphSize()} fontWeight={"light"}>
                {description}
            </Paragraph>
        </div>
    );
}