import React from 'react';
import { renderRule, StructuredText } from "react-datocms";
import { isBlockquote, isHeading, isLink, isList, isListItem, isParagraph } from "datocms-structured-text-utils";
import Paragraph from "components/elements/Paragraph";
import { IHeadings } from "components/elements/Heading";
import { formatToDashedString } from "helpers";
import Media from "components/elements/Media";
import { ITextSize } from "types/base";
import CtaBlockBlog from "../../../blocks/cta/CtaBlockBlog";

type Props = {
    content: any;
    paragraphSize?: ITextSize;
    paragraphClassName?: string,
    headingClassName?: string,
};

export default function Content(props: Props) {
    const { content, paragraphSize, paragraphClassName, headingClassName } = props;

    const paragraph = [
        renderRule(
            isParagraph,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                return (
                    <Paragraph
                        key={key}
                        theme={'default'}
                        fontWeight={"light"}
                        size={paragraphSize ? paragraphSize : 'small'}
                        className={paragraphClassName ? paragraphClassName : ''}
                    >
                        {children}
                    </Paragraph>
                );
            }
        )
    ];

    const list = [
        renderRule(
            isList,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                return (
                    <ul key={key} className={"mt-4"}>{children}</ul>
                );
            }
        )
    ];

    const listItem = [
        renderRule(
            isListItem,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                return (
                    <li className={'flex flex-row w-full pb-4 mt-1'} key={key}>
                        <svg
                            width="19"
                            height="20"
                            className={'mr-3 lg:mr-5 mt-0.5'}
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <rect y="0.286621" width="19" height="19" rx="9.5" fill="#3430FF"/>
                            <rect
                                x="4.37207"
                                y="9.14014"
                                width="2.01491"
                                height="6.13206"
                                rx="1.00746"
                                transform="rotate(-37.7939 4.37207 9.14014)"
                                fill="white"
                            />
                            <rect
                                x="12.9346"
                                y="5.20068"
                                width="2.01491"
                                height="9.77813"
                                rx="1.00746"
                                transform="rotate(35.0307 12.9346 5.20068)"
                                fill="white"
                            />
                        </svg>

                        <div className={'flex-1'}>{children}</div>
                    </li>
                );
            }
        )
    ];

    const heading = [
        renderRule(
            isHeading,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {

                const headingType = `h${node.level}` as IHeadings;

                // TODO: PLEASE REFACTOR THIS
                const getHeadingId = () => {
                    if (typeof children[0] === "string") {
                        return formatToDashedString(children[0]) as any;
                    } else if (typeof children[0]?.props?.children[0] === "string") {
                        return formatToDashedString(children[0]?.props?.children[0]) as any;
                    }

                    return "";
                };

                switch (headingType) {
                    case "h1":
                        return (
                            <h1
                                key={key}
                                id={getHeadingId()}
                                className={headingClassName ? headingClassName : "text-purple-dark font-light text-4xl mb-10 mt-10 mb-5"}
                            >
                                {children}
                            </h1>
                        );
                    case "h2":
                        return (
                            <h2
                                key={key}
                                id={getHeadingId()}
                                className={headingClassName ? headingClassName : "text-purple-dark font-light text-2xl lg:text-3xl mb-3 mt-10 mb-5"}
                            >
                                {children}
                            </h2>
                        );
                    case "h3":
                        return (
                            <h3
                                key={key}
                                id={getHeadingId()}
                                className={headingClassName ? headingClassName : "text-purple-dark font-light text-lg mb-2 lg:text-xl lg:mb-3 mt-10 mb-5"}
                            >
                                {children}
                            </h3>
                        );
                    case "h4":
                        return (
                            <h4
                                key={key}
                                id={getHeadingId()}
                                className={headingClassName ? headingClassName : "text-purple-dark font-light text-md mb-2 lg:text-lg lg:mb-3 mt-10 mb-5"}
                            >
                                {children}
                            </h4>
                        );
                }
            }
        )
    ];

    const anchor = [
        renderRule(
            isLink,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                const isTargetBlank = node.meta?.find((node) => node.value == "_blank");

                return (
                    <a key={key} href={node.url} target={isTargetBlank ? isTargetBlank.value : null}>
                        <span className={"text-orange hover:underline"}>{children}</span>
                    </a>
                );
            }
        )
    ];
    
    const blockquote = [
        renderRule(
            isBlockquote,
            ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                let quote = "";
                children.forEach((child) => quote = child.props.children);
                return (
                    <div
                        key={key}
                        className={"my-10 px-8 py-4 border-l-4 border-purple-tint-light text-purple-tint-dark font-thin text-lg"}
                    >
                        {quote}
                    </div>
                );
            }
        )
    ];

    const formatStructuredTextToParagraph = () => {
        return [
            ...paragraph,
            ...heading,
            ...anchor,
            ...blockquote,
            ...listItem,
            ...list,
        ];
    };

    const renderBlocks = ({ record }: any) => {
        switch (record.__typename) {
            case 'MediaObjectRecord':
                return (
                    <Media {...record} className={"my-10"}/>
                );
            case 'Cta2Record':
                return (
                    <CtaBlockBlog {...record.cta} className={"my-1"}/>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <StructuredText
                data={content}
                customRules={formatStructuredTextToParagraph()}
                renderBlock={renderBlocks}
            />
        </>

    );
}