import React from 'react';
import { IImage, ITextAlignment, IThemes } from "types/base";
import { Image } from "react-datocms";
import Container from "components/core/Container";
import cn from "classnames";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";
import Text from "components/typography/Text";


export type IQuote = {
    __typename: string,
    id: string,
    theme: IThemes,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    logoImage: IImage,
    quoteText: string,
    quoteSource: string,
    quoteSourceImage: IImage,
    quoteSourceDescription: string,
    container: boolean
};

export default function Quote(props: IQuote) {
    const {
        theme,
        lessSpaceTop,
        lessSpaceBottom,
        quoteSource,
        quoteText,
        quoteSourceDescription,
        container
    } = props;
    const quoteSourceImage = props.quoteSourceImage?.responsiveImage;
    const logoImage = props.logoImage?.responsiveImage;

    const containerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn,
    );

    const innerContainerCn = cn(
        "flex flex-col",
        "content-center text-center",
        container && "py-10 lg:py-16",
    );

    const logoTextAndImageContainerCn = cn(
        "flex flex-col items-center",
        container && "px-6 lg:px-16"
    );

    const textWrapper = cn(
        "flex flex-col"
    );

    const logoImageContainer = cn(
        'h-24 w-24 object-scale-down',
        container ? "mb-6 lg:mb-8" : "mb-8",
    );

    const quoteTextContainer = cn(
        container ? "mb-6 lg:mb-8" : "mb-8",
    );

    return (
        <Container theme={theme} isRounded={container} className={containerCn}>
            <div className={innerContainerCn}>
                <div className={logoTextAndImageContainerCn}>
                    {logoImage && (
                        <Image data={logoImage} className={logoImageContainer}/>
                    )}
                    <Text
                        className={quoteTextContainer}
                        weight={"normal"}
                        color={theme == "dark" ? "text-white" : "text-gray-900"}
                        size={{ sm: "3xl", md: "5xl" }}
                    >
                        {quoteText}
                    </Text>
                    {quoteSourceImage && (
                        <Image data={quoteSourceImage} className={'h-24 w-24 object-scale-down rounded-full mb-4'}/>
                    )}
                    {quoteSource && (
                        <div className={textWrapper}>
                            <Text
                                weight={'medium'}
                                color={theme == "dark" ? "text-white" : "text-gray-900"}
                                size={"lg"}
                                className={"mb-2"}
                            >
                                {quoteSource}
                            </Text>
                            <Text weight={'light'} color={theme == "dark" ? "text-white" : "text-gray-500"} size={"md"}>
                                {quoteSourceDescription}
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        </Container>
    );
}