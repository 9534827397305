import React from "react";
import cn from "classnames";
import { IImage, ITextAlignment } from "types/base";
import { Image } from "react-datocms";
import useScript from "react-script-hook";

type Props = {
    image?: IImage;
    align: ITextAlignment;
    className?: string;
    meetingsUrl: string;
};

export default function PlanOnboardingIframe(props: Props) {
    const { align, className, meetingsUrl } = props;

    useScript({
        src: "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    });

    const onboardingCn = cn(
        "rounded-2xl",
        align == 'center' ? 'w-12/12 mx-auto' : '',
        className
    );

    const customStyles = () => {
        const backgroundColor = "rgb(36, 25, 89)";

        if(align == "center") {
            return ({
                background: backgroundColor,
                width: "100%"
            });
        }

        return ({
            background: backgroundColor,
            maxWidth: "38rem",
            maxHeight: "40rem"
        });
    };

    return (
        <div className={onboardingCn} style={customStyles()}>
            <div className={"meetings-iframe-container"} data-src={meetingsUrl} />
        </div>
    );
}