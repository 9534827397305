import React from 'react';
import Container from "components/core/Container";
import cn from "classnames";
import TextBlockImage from "blocks/text/TextBlockImage";
import TextBlockContent from "blocks/text/TextBlockContent";
import { IButton, ILottie, IThemes } from "types/base";
import { IForm } from "blocks/cta/CtaBlock";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";
import Lottie from "react-lottie-player";

export type ITextBlockAlignments = "left" | "center" | "right";

export type ITextBlock = {
    __typename?: string,
    id?: string,
    isHero?: boolean;
    largeText?: boolean;
    lessSpaceTop?: boolean;
    lessSpaceBottom?: boolean;
    theme: IThemes,
    align: ITextBlockAlignments,
    preHeading?: string,
    title: string,
    description: any,
    image?: any,
    callToAction?: IButton[] | IForm[],
    animation?: ILottie,
};

type Props = {
    className?: string
    noContainer?: boolean;
};

export default function TextBlock(props: ITextBlock & Props) {
    const { image, theme, align, lessSpaceBottom, lessSpaceTop, animation } = props;

    const hasAnimation = animation != null;

    const innerContainerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    const generateColumns = () => {
        switch (align) {
            case "center":
                return `grid grid-cols-1 gap-y-6 ${hasAnimation ? 'h-1/2': ''}`;
            case "left":
                return `grid grid-cols-1 ${image || animation ? 'lg:grid-cols-2' : ''}`;
            case "right":
                return `grid grid-cols-1 grid-rows-1 ${image || animation ? 'lg:grid-cols-2' : ''}`;
        }
    };

    const columnsClasses = cn(
        generateColumns(),
    );

    const isMobile = cn(
        innerContainerCn,
        "grid",
        "lg:hidden"
    );

    const isDesktop = cn(
        innerContainerCn,
        "hidden",
        "lg:grid"
    );

    const TextBlock = () => {
        return (
            <>
                <Container className={isDesktop} theme={theme}>
                    <div className={columnsClasses}>
                        {align == "right" && (
                            <>
                                {image && (
                                    <TextBlockImage {...props} className={'my-auto'}/>
                                )}
                                {hasAnimation && (
                                    <Lottie
                                        path={animation.url}
                                        loop
                                        play={true}
                                        style={{ width: "auto", height: "auto" }}
                                    />
                                )}
                                <TextBlockContent {...props} className={'my-auto pl-16'} />
                            </>
                        )}
                        {align == "left" && (
                            <>
                                <TextBlockContent {...props} className={'my-auto pr-16'}/>
                                {image && (
                                    <TextBlockImage {...props} className={'my-auto'}/>
                                )}
                                {hasAnimation && (
                                    <Lottie
                                        path={animation.url}
                                        loop
                                        play={true}
                                        style={{ width: "auto", height: "auto" }}
                                        className={'my-auto '}
                                    />
                                )}
                            </>
                        )}
                    </div>
                    {align == "center" && (
                        <>
                            <TextBlockContent {...props} />
                            {image && (
                                <TextBlockImage {...props} className={'my-auto'}/>
                            )}
                            {hasAnimation && (
                                <div className={'h-[40rem]'}>
                                    <Lottie
                                        path={animation.url}
                                        loop
                                        play={true}
                                        style={{ width: "100%", height: '100%' }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Container>
                <Container className={isMobile} theme={theme}>
                    <div className={columnsClasses}>
                        <TextBlockContent {...props} />
                        {image && (
                            <TextBlockImage {...props}/>
                        )}
                        {hasAnimation && (
                            <Lottie
                                path={animation.url}
                                loop
                                play={true}
                                style={{ width: "auto", height: "auto" }}
                            />
                        )}
                    </div>
                </Container>
            </>
        );
    };

    return <TextBlock/>;
}