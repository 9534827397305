import React from 'react';
import UspBlock, { IUspBlock } from "blocks/usp/UspBlock";
import Container from "components/core/Container";
import { ITextAlignment, IThemes } from "types/base";
import PreHeading from "components/elements/PreHeading";
import Heading from "components/elements/Heading";
import {
    adjustAlignment,
    noSpaceTopCn,
    lessSpaceTopCn,
    lessSpaceBottomCn,
    normalSpaceTopCn,
    normalSpaceBottomCn
} from "classes";

import cn from "classnames";
import Paragraph from "components/elements/Paragraph";

export type IUspListBlock = {
    __typename: string,
    id: string,
    theme: IThemes,
    noSpaceTop: boolean,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    hasTitle: boolean,
    title?: string,
    description?: any,
    preHeading?: string,
    alignment: ITextAlignment,
    usps: IUspBlock[],
};

type Props = {
    className?: string,
    inTextBlock?: boolean,
};

export default function UspList(props: IUspListBlock & Props) {
    const {
        theme,
        noSpaceTop,
        lessSpaceTop,
        lessSpaceBottom,
        usps,
        hasTitle,
        title,
        preHeading,
        description,
        alignment,
    } = props;

    const container = props.usps[0].container;
    const numberOfUspBlocks = usps.length;

    const uspLayout = cn(
        "flex flex-col md:grid",
        { "md:grid-cols-6 md:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1" : numberOfUspBlocks == 3 },
        { "md:grid-cols-2 md:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1" : numberOfUspBlocks == 4 },
        { "md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2" : numberOfUspBlocks == 6 },
        { "md:grid-cols-2 md:grid-rows-4 lg:grid-cols-4 lg:grid-rows-2" : numberOfUspBlocks == 8 },
        container ? "gap-6" : "gap-10 md:gap-20",
        hasTitle && "pt-12 lg:pt-16"
    );

    const descriptionCn = cn(
        "text-lg xl:text-xl",
        "text-lg xl:text-xl",
        { "text-gray-500" : theme == "default" },
        { "text-gray-500" : theme == "gray" },
        { "text-gray-200" : theme == "dark" },
        adjustAlignment(alignment)
    );

    const innerContainerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    const innerContainerCnNoSpaceTop = cn(
        noSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    const List = () => {
        return (
            <div className={noSpaceTop ? innerContainerCnNoSpaceTop : innerContainerCn}>
                {hasTitle && (
                    <div className={`text-${alignment} pt-8 pb-0 lg:pt-8 md:mx-auto`}>
                        {preHeading && (
                            <PreHeading
                                size={"medium"}
                                theme={theme}
                            >
                                {preHeading}
                            </PreHeading>
                        )}
                        <Heading
                            renderAs={"h2"}
                            theme={theme}
                        >
                            {title}
                        </Heading>
                        {description && (
                            <div>
                                <Paragraph
                                    renderAs={"structuredText"}
                                    className={descriptionCn}
                                    fontWeight={"light"}
                                    theme={theme}
                                >
                                    {description}
                                </Paragraph>
                            </div>
                        )}
                    </div>
                )}
                <div className={uspLayout}>
                    {usps.map((usp) => {
                        return (
                            <UspBlock
                                {...usp}
                                key={usp.id}
                                theme={theme}
                                alignment={hasTitle ? alignment : "left"}
                                children={numberOfUspBlocks}
                            />
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <Container theme={theme}>
            <List/>
        </Container>
    );
}