import React from 'react';
import Container from "components/core/Container";
import Roi, { IRoi } from "blocks/roi/Roi";
import { ITextAlignment, ITextSize, IThemes } from "types/base";
import cn from "classnames";
import Paragraph from "components/elements/Paragraph";
import Heading from "components/typography/Heading";

export type IRoiList = {
    __typename: string,
    id: string,
    alignment: ITextAlignment,
    theme: IThemes,
    size: ITextSize,
    roi: IRoi[],
    container: boolean,
    hasTitle?: boolean,
    title?: string,
    description: any,
    containerNoTitle: boolean,
    noContainerWithTitle: boolean,
    noContainerNoTitle: boolean,
    containerWithTitle: boolean
};

export default function RoiList(props: IRoiList) {
    const { size, alignment, theme, hasTitle, title, description, container } = props;

    const noContainerWithTitle = !container && hasTitle;
    const noContainerNoTitle = !container && !hasTitle;

    const roiListCn = cn(
        "flex flex-col md:grid",
        "md:grid-rows-2 md:grid-cols-2",
        "lg:grid-rows-1 lg:grid-cols-3",
        "gap-x-20 gap-y-8 md:gap-y-12",
        noContainerNoTitle && "pt-16 md:pt-20 lg:pt-24",
        noContainerWithTitle && "pt-0",
        container && "py-10 md:py-16 px-6 md:px-16"
    );

    const headerCn = cn(
        { "text-gray-900": props.theme == "default" },
        { "text-gray-500": props.theme == "gray" },
        { "text-white": props.theme == "dark" },
        container && "text-gray-900",
        "font-medium"
    );

    const descriptionWrapper = cn(
        !container && "mb-8 lg:mb-16"
    );

    const descriptionCn = cn(
        "text-lg xl:text-xl",
        { "text-gray-500": props.theme == "default" },
        { "text-gray-500": props.theme == "gray" },
        { "text-gray-200": props.theme == "dark" },
        container && "text-gray-500"
    );

    const headerAndDescriptionWrapper = cn(
        `text-${alignment}`,
        container && "px-4 md:px-8 pt-8 lg:pt-8",
        !container && "px-4 md:px-8 pt-16 lg:pt-16",
        "pb-0 lg:pb-8",
        "md:mx-auto"
    );

    const textBackGroundColorCn = cn(
        { "bg-white": props.theme == "default" && !container },
        { "bg-primary-25": props.theme == "gray" && !container },
        { "bg-primary-900": props.theme == "dark" && !container },
    );

    const whitespaceCn = cn(
        container && "py-12 md:py-16"
    );

    return (
        <div className={textBackGroundColorCn}>
            {hasTitle && (
                <div className={headerAndDescriptionWrapper}>
                    <div className={"mb-4 lg:mb-5"}>
                        <Heading size={"md"} weight={"semibold"} className={headerCn}>
                            {title}
                        </Heading>
                    </div>
                    {description && (
                        <div className={descriptionWrapper}>
                            <Paragraph
                                renderAs={"structuredText"}
                                className={descriptionCn}
                                fontWeight={"medium"}
                                theme={theme}
                            >
                                {description}
                            </Paragraph>
                        </div>
                    )}
                </div>
            )}
            <div className={whitespaceCn}>
                <Container theme={theme} isRounded={container}>
                    <div className={roiListCn}>
                        {props.roi.map((i) => <Roi
                            key={i.id}
                            {...i}
                            size={size}
                            container={container}
                            alignment={alignment}
                            theme={theme}
                        />)}
                    </div>
                </Container>
            </div>
        </div>
    );
}