import React from 'react';
import { Image } from "react-datocms";
import { IImage, IThemes } from "types/base";
import Container from "components/core/Container";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";
import cn from "classnames";
import Text from "components/typography/Text";

export type ICaseQuote = {
    __typename: string,
    id: string,
    theme: IThemes,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    rightTextAlign: boolean,
    logoImage: IImage,
    quoteSourceDescription: string,
    quoteText: string,
    quoteSource: string,
    quoteSourceImage: IImage,
};

export default function CaseQuote(props: ICaseQuote) {
    const { theme, lessSpaceTop, lessSpaceBottom, quoteSource, quoteText, quoteSourceDescription, rightTextAlign } = props;

    const quoteSourceImage = props.quoteSourceImage?.responsiveImage;
    const logoImage = props.logoImage?.responsiveImage;
    const containerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn,
    );

    return (
        <Container theme={theme} className={containerCn}>
            <div className={`flex flex-col ${rightTextAlign ? 'lg:flex-row-reverse' : 'lg:flex-row'} justify-between lg:content-between lg:gap-x-16`}>
                <div className={"flex flex-col my-auto lg:w-1/2"}>
                    <div className={"flex flex-col"}>
                        {logoImage && (
                            <Image
                                data={logoImage}
                                className={"h-24 w-24 object-scale-down mb-6 lg:mb-8"}
                            />
                        )}
                        <Text
                            className={"leading-relaxed mb-8 lg:mb-12"}
                            weight={"normal"}
                            color={theme == "dark" ? "text-white" : "text-gray-900"}
                            size={{ sm: "3xl", md: "5xl" }}
                        >
                            {quoteText}
                        </Text>
                        {quoteSource && (
                            <div className={"flex flex-col"}>
                                <Text
                                    weight={'medium'}
                                    color={theme == "dark" ? "text-white" : "text-gray-900"}
                                    size={"lg"}
                                    className={"mb-2"}
                                >
                                    {quoteSource}
                                </Text>
                                <Text
                                    weight={'light'}
                                    color={theme == "dark" ? "text-white" : "text-gray-500"}
                                    size={"md"}
                                    className={"mb-12 lg:mb-0"}
                                >
                                    {quoteSourceDescription}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
                <div className={'flex lg:justify-end lg:w-1/2'}>
                    {quoteSourceImage &&
                        <Image
                            data={quoteSourceImage}
                            className={'flex'}
                            pictureClassName={'object-cover'}
                        />
                        }
                </div>
            </div>
        </Container>
    );
}
