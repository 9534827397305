import React from 'react';
import { IButton, IImage, ITextAlignment, IThemes } from "types/base";
import Paragraph from "components/elements/Paragraph";
import Button from "components/elements/Button";
import Link from "components/base/Link";
import cn from "classnames";
import CtaForm from "blocks/cta/CtaForm";
import Container from "components/core/Container";
import Text from "components/typography/Text";

export type IButtonList = {
    __typename: string,
    id: string,
    buttons: IButton[],
};

export type IForm = {
    __typename: string,
    id: string,
    placeholder: string,
    button: IButton[],
};

export type ICtaBlock = {
    id?: string,
    alignment: ITextAlignment,
    container: boolean,
    title: string,
    description: any,
    ctaForm?: IButtonList[] | IForm,
    image?: IImage,
    theme: IThemes,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    hasAwards: boolean
};

type Props = {
    container?: boolean;
};

export default function CtaBlockBlog(props: ICtaBlock & Props) {
    const {
        title,
        description,
        container,
    } = props;
    const ctaForm = props.ctaForm ? props.ctaForm[0] : "";

    // There is always exact 1 ctaForm, but DatoCMS requires an array.
    const isButtonList = ctaForm.__typename == "ButtonListRecord";
    const isForm = ctaForm.__typename == "FormRecord";

    const ctaFormCn = cn(
        "flex bg-white rounded-xl md:w-96 lg:w-150",
        isForm && "border rounded-xl border-gray-100 lg:border-0",
    );

    return (
        <Container theme={props.theme} isRounded={container} className={''} style={{ marginBottom: "-1px" }}>
            <div className={'flex flex-col md:flex-row'}>
                <div className={"flex flex-col w-full justify-between md:flex-row px-6 lg:px-8"}>
                    <div className={'flex flex-col pt-10 md:py-auto'}>
                        <div className={'mb-4 lg:mb-5'}>
                            <Text weight={'medium'} className={'mb-4 lg:mb-5'} size={'2xl'}>
                                {title}
                            </Text>
                        </div>
                        <div className={container ? "mb-6 lg:mb-8" : "mb-8 lg:mb-10"}>
                            <Paragraph
                                className={'text-md md:text-lg'}
                                renderAs={"structuredText"}
                                theme={props.theme}
                                fontWeight={"light"}
                            >
                                {description}
                            </Paragraph>
                        </div>
                    </div>
                    <div className={'flex flex-col pb-8 md:pt-8 gap-2'}>
                        {isButtonList && ctaForm.buttons.map((button: IButton) =>
                            <Link
                                key={button.id}
                                {...button.link[0]}
                                className={""}
                            >
                                <div className={'flex'}>
                                    <Button
                                        id={button.id}
                                        variant={button.variant}
                                        size={button.size}
                                        color={button.color}
                                        textColor={button.textColor}
                                        icon={button.icon}
                                        className={"flex"}
                                    >
                                        {button.label}
                                    </Button>
                                </div>
                            </Link>
                        )}

                        {isForm && (
                            <CtaForm
                                {...ctaForm}
                                className={ctaFormCn}
                            />
                        )}

                        {isForm && (
                            ctaForm.button.map((button: IButton) =>
                                <div
                                    key={button.id + button.label}
                                    className={"lg:hidden lg:h-0 block h-full"}
                                >
                                    <Link {...button.link[0]}>
                                        <Button
                                            id={button.id}
                                            variant={button.variant}
                                            size={button.size}
                                            color={button.color}
                                            textColor={button.textColor}
                                            icon={button.icon}
                                        >
                                            {button.label}
                                        </Button>
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
}