import React from "react";
import cn from "classnames";
import { HeadingRenderTypes, SizeTypes, WeightTypes } from "./typographyTypes";
import { TailwindColors } from "components/typography/Text";

type Props = {
    children: any;
    className?: string;
    hidden?: boolean;
    size: SizeTypes;
    weight: WeightTypes;
    color?: TailwindColors;
    render_as?: HeadingRenderTypes;
};

export default function Heading(props: Props) {
    const { size, weight, render_as, color } = props;

    const sizeCn = cn(
        { "text-xl md:text-2xl": size == "xs" },
        { "text-2xl md:text-3xl": size == "sm" },
        { "text-2xl md:text-4xl": size == "md" },
        { "text-4xl md:text-5xl": size == "lg" },
        { "text-5xl md:text-6xl": size == "xl" },
    );

    const weightCn = cn(
        { "font-book": weight == "light" },
        { "font-normal": weight == "normal" },
        { "font-medium": weight == "medium" },
        { "font-bold": weight == "bold" },
    );

    const classNames = cn(
        sizeCn,
        weightCn,
        color,
        props.className ?? ""
    );

    const Component = () => {
        if (render_as == "h1") {
            return (
                <h1
                    hidden={props.hidden}
                    className={classNames}
                >
                    {props.children}
                </h1>
            );
        }

        if (render_as == "h2") {
            return (
                <h2
                    hidden={props.hidden}
                    className={classNames}
                >
                    {props.children}
                </h2>
            );
        }

        if (render_as == "h3") {
            return (
                <h3
                    hidden={props.hidden}
                    className={classNames}
                >
                    {props.children}
                </h3>
            );
        }

        if (render_as == "h4") {
            return (
                <h4
                    hidden={props.hidden}
                    className={classNames}
                >
                    {props.children}
                </h4>
            );
        }

        if (render_as == "h5") {
            return (
                <h5
                    hidden={props.hidden}
                    className={classNames}
                >
                    {props.children}
                </h5>
            );
        }

        return null;
    };

    return <Component/>;
}

Heading.defaultProps = {
    size: "md",
    weight: "normal",
    render_as: "h2",
};