import React from 'react';
import Container from "components/core/Container";
import Content from "components/blog/blogPost/Content";

export type ISimpleTextBlock = {
    __typename: string,
    id: string,
    referenceId: string
    text: any;
};

export default function SimpleTextBlock(props: ISimpleTextBlock) {
    return (
        <Container className={'mb-16 pt-20'}>
            <Content
                content={props.text}
                paragraphSize={'medium'}
                paragraphClassName={'text opacity-60'}
            />
        </Container>
    );
}