import React from "react";
import cn from "classnames";
import { IImage, ITextAlignment } from "types/base";
import { Image } from "react-datocms";

type Props = {
    image?: IImage;
    align: ITextAlignment;
    className?: string;
};

export default function TextBlockImage(props: Props) {
    const { align, className, image } = props;

    const imageContainer = cn(
        align == 'center' ? 'w-6/6 mx-auto' : '',
        className
    );

    return (
        <div className={imageContainer}>
            {image.responsiveImage && (
                <Image data={image.responsiveImage} pictureClassName={'object-contain'}/>
                )}
        </div>
    );
}

