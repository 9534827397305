import { Image } from "react-datocms";
import React from "react";
import { IMedia, IVideo } from "types/base";
import cn from "classnames";
import ReactPlayer from 'react-player';

type Props = {
    className?: string;
};

export default function Media(props: Props & IMedia) {
    const className = cn(
        props.className ?? ""
    );

    if(props.image) {
        return (
            <Image className={className} data={props.image.responsiveImage} />
        );
    }

    if(props.video) {
        return (
            <ReactPlayer url={props.video.url} width={"100%"} controls={true} light={true} />
        );
    }

    if(props.wistiaVideo) {
        return (
            <ReactPlayer url={props.wistiaVideo} width={"100%"} controls={true} />
        );
    }

    return <></>;
}