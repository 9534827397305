import React from 'react';
import { IImage } from "types/base";
import { Image as DatoImage } from "react-datocms";

type Props = {

};

export default function Image(props: IImage & Props)
{

    const responsiveImage = props.responsiveImage;

    return (
        <DatoImage data={responsiveImage}  />
    );
}