import React from 'react';
import { IButton, IImage } from "types/base";
import { Image } from "react-datocms";
import Button from "components/elements/Button";
import cn from "classnames";
import Link from "components/base/Link";
import Container from "components/core/Container";

export type ICaseBlockRecord = {
    cases: ICase[]
};

export type ICase = {
    id: string,
    title: string,
    image: IImage,
    button: IButton[]
};

type Props = {
    className?: string,
};

export default function CaseBlock(props: ICaseBlockRecord & Props) {
    const { className } = props;

    const quoteContainerCn = cn(
        "bottom-4 absolute z-10 ",
        "sm:bottom-20 sm:w-3/4",
        "md:w-2/4",
        "lg:bottom-24",
        "xl:bottom-36",
    );

    const buttonCn = cn(
        "text-sm rounded mt-3",
        "lg:text-lg lg:mt-10"
    );

    return (
        <div className={className}>
            {props?.cases?.map((c) => {
                return (
                    <div className={"relative"} key={c.id}>
                        <div className={"filter brightness-50"} style={{ maxHeight: "50rem", overflow: "hidden" }}>
                            <Image data={c?.image?.responsiveImage} />
                        </div>
                        <Container>
                            <div className={quoteContainerCn}>
                                {c?.button.map((b) => {
                                    return (
                                        <Link {...b.link[0]} key={b.id}>
                                            <Button
                                                id={b.id}
                                                variant={b.variant}
                                                size={b.size}
                                                color={b.color}
                                                textColor={b.textColor}
                                                icon={b.icon}
                                                className={buttonCn}
                                            >
                                                {b.label}
                                            </Button>
                                        </Link>
                                    );
                                })}
                            </div>
                        </Container>
                    </div>
                );
            })}
        </div>
    );
}