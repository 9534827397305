import React from 'react';
import Container from "components/core/Container";
import cn from "classnames";
import TextBlockContent from "blocks/text/TextBlockContent";
import { IButton, IThemes } from "types/base";
import { ITextBlockAlignments } from 'blocks/text/TextBlock';
import PlanOnboardingIframe from "blocks/planOnboarding/PlanOnboardingIframe";

export type ITextBlock = {
    __typename?: string,
    id?: string,
    theme: IThemes,
    align: ITextBlockAlignments,
    preHeading?: string,
    title: string,
    description: any,
    meetingsUrl: string;
    button: IButton[]
};

type Props = {
    className?: string,
};

export default function PlanOnboarding(props: ITextBlock & Props) {
    const { theme, align, className, button } = props;

    const generateColumns = () => {
        switch (align) {
            case "center":
                return 'grid grid-cols-1 gap-y-6';
            case "left":
                return `grid grid-cols-1 lg:grid-cols-2`;
            case "right":
                return `grid grid-cols-1 grid-rows-1 lg:grid-cols-2`;
        }
    };

    const columnsClasses = cn(
        generateColumns(),
        'pt-16 gap-x-20 pb-10'
    );

    const isMobile = cn(
        "grid",
        "lg:hidden"
    );

    const isDesktop = cn(
        "hidden",
        "lg:grid"
    );

    return (
        <>
            <Container className={isDesktop} theme={theme}>
                <div className={columnsClasses}>
                    {/* Should do something about this. */}
                    {align == "right" ? (
                        <>
                            <PlanOnboardingIframe {...props} className={'my-auto'} />
                            <TextBlockContent {...props}/>

                        </>
                    ) : (
                        <>
                            <TextBlockContent {...props}/>
                            <PlanOnboardingIframe {...props} className={'my-auto'} />
                        </>
                    )}
                </div>
            </Container>

            <Container className={isMobile} theme={theme}>
                <div className={columnsClasses}>
                    <TextBlockContent {...props} className={className} />
                    <PlanOnboardingIframe {...props} className={'my-auto mx-auto'} />
                </div>
            </Container>
        </>
    );
}