import React, { useState } from "react";
import { IIcon, ILink, ITextAlignment, IThemes } from "types/base";
import Heading from "components/elements/Heading";
import Paragraph from "components/elements/Paragraph";
import Link from "components/base/Link";
import Icon from "components/elements/Icon";
import cn from "classnames";

type Props = {
    isLink?: boolean;
    theme: IThemes;
    className?: string,
    inTextBlock?: boolean;
    alignment?: ITextAlignment;
    isClickable?: boolean;
};

export type IUspBlock = {
    id: string,
    iconBoxColor?: "orange" | "green" | "blue" | "purple" | "red" | "yellow" | "colorless",
    container: boolean,
    children: number
    title?: string,
    description: any,
    icon?: IIcon,
    link?: ILink[]
    isWithoutColor: boolean
};

function UspBlock(props: Props & IUspBlock) {
    const { title, description, icon, theme, inTextBlock, container, iconBoxColor } = props;
    const isLink = props.link.length > 0;
    const transition = "transition-all ease-in-out duration-300";
    const quickTransition = "transition-all ease-in-out duration-100";
    const isWithoutColor = !container && iconBoxColor == "colorless";

    const uspSizer = cn(
        "w-full",
        container && isLink && "group",
        { "md:col-span-3 md:last:col-start-2 md:last:col-span-4 md:last:px-12 lg:col-span-1 lg:last:col-span-1 lg:last:px-0" : props.children == 3 },
    );

    const contentHoverCn = cn(
        isLink && transition,
        { "group-hover:text-white" : isLink },
        { "mt-2" : inTextBlock }
    );

    const boxlessCn = cn(
        { "bg-purple" : iconBoxColor == "purple" },
        { "bg-yellow" : iconBoxColor == "yellow" },
        { "bg-green" : iconBoxColor == "green" },
        { "bg-blue" : iconBoxColor == "blue" },
        { "bg-red" : iconBoxColor == "red" },
        { "bg-orange" : iconBoxColor == "orange" },
    );

    const colorlessIconCn = cn(
        { "bg-white" : theme == "default" },
        { "bg-primary-25" : theme == "gray" },
        { "bg-primary-900" : theme == "dark" },
    );

    const iconColorCn = cn(
        "h-12 w-12 rounded-lg mb-4 sm:mb-5",
        container ? "bg-primary-200" : boxlessCn,
        isWithoutColor && colorlessIconCn,
        { "group-hover:bg-primary-900" : isLink },
    );

    const colorlessIconStrokeCn = cn(
        { "bg-white" : theme == "dark" },
        { "bg-primary-900" : theme == "gray" },
        { "bg-primary-900" : theme == "default" }
    );

    const iconStrokeCn = cn(
        { "group-hover:bg-white" : isLink },
        container ? "bg-primary-900" : "bg-white",
        isWithoutColor && colorlessIconStrokeCn
    );

    const uspContainer = cn(
        "rounded-2xl h-full w-full",
        container ? "bg-primary-50 p-5 md:p-6" : "bg-white p-0",
        isLink && `group-hover:bg-primary-600 ${quickTransition}`,
        { "bg-primary-900" : theme == "dark" && !container },
        { "bg-primary-25" : theme == "gray" && !container },
    );

    const headingCn = cn(
        "overflow-hidden mt-2 mb-2",
        contentHoverCn
    );

    const uspDescription = cn(
        "text-base overflow-auto",
        theme == "dark" ? "text-gray-200" : "text-gray-500",
        contentHoverCn,
    );

    const diagonalArrowCn = cn(
        "hidden group-hover:inline-flex",
        `bg-white ${transition}`
    );

    if (isLink) {
        return (
            <Link className={uspSizer} {...props.link[0]} >
                <div className={uspContainer}>
                    <div className={"flex flex-col"}>
                        <div className={"flex flex-row justify-between"}>
                            {icon && (
                                <div className={iconColorCn}>
                                    <Icon
                                        url={icon.source.url}
                                        className={`${iconStrokeCn} ${transition}`}
                                        size={"medium"}
                                        theme={theme}
                                    />
                                </div>
                            )}
                                <Icon
                                    url={"/static/icons/arrow-diagonal.svg"}
                                    className={diagonalArrowCn}
                                    size={"extra-small"}
                                    theme={theme}
                                />
                        </div>
                        <div className={"flex flex-col w-full overflow-auto"}>
                            {title && (
                                <Heading
                                    renderAs={"h4"}
                                    theme={theme}
                                    fontWeight={"medium"}
                                    className={headingCn}
                                >
                                    {title}
                                </Heading>
                            )}
                            <div className={"pr-6"}>
                                <Paragraph
                                    renderAs={"structuredText"}
                                    theme={theme}
                                    fontWeight={"light"}
                                    className={uspDescription}
                                >
                                    {description}
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    return (
        <div className={uspSizer}>
            <div className={uspContainer}>
                <div className={"flex flex-col"}>
                    {icon && (
                        <div className={iconColorCn}>
                            <Icon
                                url={icon.source.url}
                                className={`${iconStrokeCn} ${transition}`}
                                size={"medium"}
                                theme={theme}
                            />
                        </div>
                    )}
                    <div>
                        <Heading
                            renderAs={"h4"}
                            theme={theme}
                            fontWeight={"medium"}
                            className={headingCn}
                        >
                            {title}
                        </Heading>
                        <div>
                            <Paragraph
                                renderAs={"structuredText"}
                                theme={theme}
                                fontWeight={"light"}
                                className={uspDescription}
                            >
                                {description}
                            </Paragraph>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UspBlock;

