import React from 'react';
import { IButton, IThemes } from "types/base";
import Container from "components/core/Container";
import Heading from "components/typography/Heading";
import Image from "components/elements/Image";
import Paragraph from "components/elements/Paragraph";
import Text from "components/typography/Text";
import cn from "classnames";
import Link from "components/base/Link";
import Button from "components/elements/Button";
import ReactPlayer from "react-player";

export type IHero = {
    __typename?: string,
    id?: string,
    preHeading?: string,
    title: string,
    description: any
    image?: any,
    callToAction?: IButton[],
    wistiaUrl?: string
};

export default function CaseHeroBlock(props: IHero) {

    const containerCn = cn(
        'pt-24',
        'mb-16 md:mb-20 lg:mb-24',
        'relative text-center',
    );

    const reactPlayerCn = cn(
        "w-[95%] lg:w-[78%] h-[10rem] lg:h-[38rem]"
    );

    return (
        <div className={containerCn}>
            <div className={"absolute h-full w-full z-10"}>
                <div className={"bg-primary-900 absolute w-full h-1/3 lg:h-1/2 bottom-0"}/>
            </div>
            <div className={"z-20 relative"}>
                <Container>
                    <div className={"md:px-10 lg:px-44 xl:px-60"}>
                        <Text color={"text-purple"} weight={"medium"}>
                            {props.preHeading}
                        </Text>
                        <Heading size={"lg"} className={"mt-3 mb-6"}>
                            {props.title}
                        </Heading>
                        <Paragraph
                            renderAs={"structuredText"}
                            size={"large"}
                            className={"text-xl text-gray-600"}
                            fontWeight={"light"}
                        >
                            {props.description}
                        </Paragraph>
                        <div className={'flex flex-col-reverse lg:flex-row mb-12 gap-3 justify-center'}>
                            {props.callToAction.map(button => (
                                <Link
                                    {...button.link[0]}
                                    key={button.id}
                                >
                                    <Button
                                        {...button}
                                        className={"mt-10 mx-auto"}
                                    >
                                        {button.label}
                                    </Button>
                                </Link>
                            ))}
                        </div>
                    </div>
                    {props.image && (
                        <div className={"mt-24"}>
                            <Image {...props.image}/>
                        </div>
                    )}
                    {props.wistiaUrl && (
                        <div className={'flex justify-center mt-24'}>
                                <ReactPlayer
                                    url={props.wistiaUrl}
                                    width={""}
                                    height={""}
                                    options={{}}
                                    className={reactPlayerCn}
                                    controls={true}
                                />
                        </div>
                    )}
                </Container>
            </div>
        </div>
    );
}