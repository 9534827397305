import React, { useState } from 'react';
import Container from "components/core/Container";
import { IImage, ITextAlignment, IThemes } from "types/base";
import { Image } from "react-datocms";
import Paragraph from "components/elements/Paragraph";
import cn from "classnames";
import { animated, useSpring } from "react-spring";
import PreHeading from "components/elements/PreHeading";
import Heading from "components/elements/Heading";
import { adjustAlignment, lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";

export type IImageSlider = {
    __typename: string,
    id: string,
    theme: IThemes,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    hasTitle?: boolean,
    title?: string,
    preHeading?: string,
    description: any,
    placeOfNavigation: string,
    alignment: ITextAlignment,
    imageSliderItem: IImageSliderItem[],
};

export type IImageSliderItem = {
    heading: string,
    description: string,
    image: IImage,
};

export default function ImageSlider(props: IImageSlider) {
    const [activeItem, setActiveItem] = useState(0);
    const {
        theme,
        placeOfNavigation,
        imageSliderItem,
        lessSpaceTop,
        lessSpaceBottom,
        hasTitle,
        title,
        preHeading,
        description,
        alignment
    } = props;

    const alignNavLeft = placeOfNavigation == 'left';
    const alignNavBottom = placeOfNavigation == "bottom";
    const transition = "transition-all ease-out-in duration-500";

    const descriptionCn = cn(
        "text-gray-500 font-light text-lg lg:text-xl",
        adjustAlignment(alignment)
    );

    const paragraphContainerCn = cn(
        "flex",
        { "justify-center": alignment == "center" },
        { "flex-start": alignment == "left" },
    );

    const innerContainerCn = cn(
        `${lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn}`,
        `${lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn}`,
    );

    const imageAndListContainerCn = cn(
        "flex",
        alignNavLeft && "flex-col lg:flex-row gap-y-12 lg:gap-y-0 lg:gap-x-16 lg:w-full",
        alignNavBottom && "flex-col-reverse gap-y-12 lg:gap-y-20",
        "pt-12 lg:pt-16"
    );

    const listOuterContainerCn = cn(
        "flex lg:mt-24",
        alignNavLeft && "flex-col lg:w-1/2",
        alignNavBottom && "flex-row justify-between gap-y-10 lg:gap-y-0 flex-wrap lg:flex-nowrap lg:w-full"
    );

    const backgroundColorsCnNew = (act: boolean) => cn(
        "cursor-pointer flex",
        alignNavLeft && "flex-col gap-y-1 lg:gap-y-2 w-full border-l-4 px-6 py-4",
        alignNavBottom && "flex-col gap-y-1 lg:gap-y-2 w-full border-t-4 text-center p-2 lg:p-8",
        act && `border-purple ${transition}`
    );

    const headingActive = cn(
        transition,
        "cursor-pointer text-primary-600",
    );

    const headingNotActive = cn(
        "cursor-pointer text-gray-900",
    );

    const imageContainerCn = cn(
        "flex h-auto lg:w-1/2"
    );

    const PreHeadingTitleDescriptionCn = cn(
        { "mx-auto w-full lg:w-2/3": alignment == "center" },
        { "w-full lg:w-2/3": alignment == "left" },
        `text-${alignment}`
    );

    const RenderImage = () => {
        const imageAppear = useSpring({ from: { opacity: 0.5 }, to: { opacity: 1 } });
        return (
            <animated.div style={imageAppear}>
                {imageSliderItem[activeItem]?.image?.responsiveImage && (
                    <Image data={imageSliderItem[activeItem].image.responsiveImage} pictureClassName={'object-fill'}/>
                )}
            </animated.div>
        );
    };

    const PreHeadingTitleDescription = () => (
            <div className={PreHeadingTitleDescriptionCn}>
                {preHeading && (
                    <PreHeading
                        size={"medium"}
                        theme={theme}
                    >
                        {preHeading}
                    </PreHeading>
                )}
                <Heading
                    renderAs={"h2"}
                    theme={theme}
                >
                    {title}
                </Heading>
                {description && (
                    <div className={paragraphContainerCn}>
                        <Paragraph
                            renderAs={"structuredText"}
                            className={descriptionCn}
                            fontWeight={"light"}
                            theme={theme}
                        >
                            {description}
                        </Paragraph>
                    </div>

                )}
            </div>
        );

    return (
        <Container theme={"default"} overflowable={true}>
            <div className={innerContainerCn}>
                {hasTitle && (
                    <PreHeadingTitleDescription/>
                )}
                <div className={imageAndListContainerCn}>
                    <div className={listOuterContainerCn}>
                        {imageSliderItem.map((li, index) => (
                            <div
                                className={backgroundColorsCnNew(activeItem == index)}
                                onClick={() => setActiveItem(index)}
                                key={li.image.id + index}
                            >
                                <Paragraph
                                    className={`text-lg lg:text-xl  ${activeItem == index ? headingActive : headingNotActive}`}
                                >
                                    {li.heading}
                                </Paragraph>
                                <Paragraph className={"text-gray-500"}>
                                    {li.description}
                                </Paragraph>
                            </div>
                        ))}
                    </div>
                    <div className={imageContainerCn}>
                        <div className={'top-28'}>
                            <RenderImage/>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}