import React from 'react';
import Container from "components/core/Container";
import { IImage, IThemes } from "types/base";
import { Image } from "react-datocms";
import cn from "classnames";
import { lessSpaceBottomCn, lessSpaceTopCn, normalSpaceBottomCn, normalSpaceTopCn } from "classes";
import Text from "components/typography/Text";

export type IImageGallery = {
    title: string,
    lessSpaceTop: boolean,
    lessSpaceBottom: boolean,
    images: IImage[],
};

type Props = {
    theme: IThemes
};

export default function ImageGallery(props: Props & IImageGallery) {
    const { theme, title, lessSpaceTop, lessSpaceBottom, images } = props;
    const numberOfImages = images.length;

    const staticColleagueContainerCn = cn(
        'grid justify-between',
        `grid-cols-1 md:grid-cols-${numberOfImages}`,
        'gap-x-8 gap-y-8 lg:gap-y-4'
    );

    const containerCn = cn(
        lessSpaceTop ? lessSpaceTopCn : normalSpaceTopCn,
        lessSpaceBottom ? lessSpaceBottomCn : normalSpaceBottomCn
    );

    return (
        <Container className={containerCn} theme={theme}>
            <div className={'flex mb-16'}>
                <Text color={'text-gray-900'} size={'7xl'} weight={'medium'} className={'text-left'}>
                    {title}
                </Text>
            </div>
            <div className={"flex relative mb-6"}>
                <div className={staticColleagueContainerCn}>
                    {images.map((colleague, index) => {
                        return (
                            <Image
                                key={colleague.id + index}
                                data={colleague.responsiveImage}
                                pictureClassName={'object-contain'}
                            />
                        );
                    })}
                </div>
            </div>
        </Container>
    );
}